import { Link, graphql, navigate, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"

import Layout from "../components/layout/layout"
import Resource from "../components/resources/resource"
import Seo from "../components/seo"
import axios from "axios"

const InterestTopicItem = ({
	data: {
		interestGroup,
		allEventItem,
		allNewsItem,
		allFreeResources,
		allResourceInterestGroups,
		allEventInterestGroups,
	},
}) => {
	const [loaded, setLoaded] = useState(false)
	const [eventFilteredItems, setEventFilteredItems] = useState([])
	const [newsFilteredItems, setNewsFilteredItems] = useState([])
	const [resourceFilteredItems, setResourceFilteredItems] = useState([])

	const eventItems = allEventItem.nodes
	const newsItems = allNewsItem.nodes
	const resourceItems = allFreeResources.nodes
	const resourceGroups = allResourceInterestGroups.nodes
	const eventGroups = allEventInterestGroups.nodes

	useEffect(() => {
		//Make sure to reset all the data
		setResourceFilteredItems([])
		setNewsFilteredItems([])
		setEventFilteredItems([])
		//Events
		filterEvents(interestGroup.interestGroupId)
		//Resources
		filterResources(interestGroup.interestGroupId)
		//News
		filterNews(interestGroup.interestGroupId)
	}, [])

	const filterResources = filter => {
		//Do the same for resources
		resourceGroups.forEach(node => {
			if (node.interestGroups.length < 1) return

			const igList = node.interestGroups

			const getMatchingNode = igList
				.map(ig => {
					if (filter.includes(ig)) {
						return node
					}
				})
				.filter(res => res !== undefined)

			const filteredResources = resourceItems.filter(node =>
				getMatchingNode.map(res => res.fileId).includes(node.file)
			)
			setResourceFilteredItems(prev => [...prev, ...filteredResources])
		})
	}

	const filterEvents = filter => {
		//Do the same for resources
		eventGroups.forEach(node => {
			if (node.interestGroups.length < 1) return

			// console.log(node.interestGroups)
			const igList = node.interestGroups

			const getMatchingNode = igList
				.map(ig => {
					if (filter.includes(ig)) {
						return node
					}
				})
				.filter(res => res !== undefined)

			const filteredEvents = eventItems.filter(node =>
				getMatchingNode.map(res => res.eventId).includes(node.eventId)
			)
			setEventFilteredItems(prev => [...prev, ...filteredEvents])
		})
	}

	const filterNews = filters => {
		newsItems.forEach(node => {
			if (node.interestGroups.length < 1) return

			// console.log(node.interestGroups)
			const igList = node.interestGroups

			const getMatchingNode = igList
				.map(ig => {
					if (filters.includes(ig.interestGroupId)) {
						return node
					}
				})
				.filter(res => res !== undefined)

			setNewsFilteredItems(prev => [...prev, ...getMatchingNode])
		})
	}

	return (
		<Layout>
			<Seo title={`IPWEA Interest Group - ${interestGroup.name}`} />
			<Header title={interestGroup.name} />
			<Tabs
				eventFilteredItems={eventFilteredItems}
				newsFilteredItems={newsFilteredItems}
				resourceFilteredItems={resourceFilteredItems}
			/>
		</Layout>
	)
}

const Header = ({ title }) => (
	<h1 className="ml-0 md:ml-12 text-4xl py-4">{title}</h1>
)

const Tabs = ({
	newsFilteredItems,
	eventFilteredItems,
	resourceFilteredItems,
}) => {
	const [openTab, setOpenTab] = useState(1)

	const handleClick = id => {
		setOpenTab(id)
	}

	return (
		<div className="ml-0 md:ml-12">
			<ul id="tabs" className="inline-flex w-full px-1 pt-2 ">
				<TabHeading
					title="Related Resources"
					current={openTab === 1 ? true : false}
					onClick={handleClick}
					id={1}
				/>
				<TabHeading
					title="Latest News"
					current={openTab === 2 ? true : false}
					onClick={handleClick}
					id={2}
				/>
				<TabHeading
					title="Upcoming Events"
					current={openTab === 3 ? true : false}
					onClick={handleClick}
					id={3}
				/>
			</ul>

			<TabContent>
				<div className={openTab === 1 ? "block  p-4" : "hidden  p-4"}>
					{resourceFilteredItems.map((item, idx) => (
						<EventItem key={idx} item={item} />
					))}
				</div>
				<div className={openTab === 2 ? "block  p-4" : "hidden  p-4"}>
					{newsFilteredItems.map((item, idx) => (
						<EventItem key={idx} item={item} />
					))}
				</div>
				<div className={openTab === 3 ? "block  p-4" : "hidden  p-4"}>
					{eventFilteredItems.map((item, idx) => (
						<EventItem key={idx} item={item} />
					))}
				</div>
			</TabContent>
		</div>
	)
}

const TabHeading = ({ title, current, onClick, id }) => {
	const selectedStyles =
		"px-4 py-2 mb-px font-semibold border-b-2 border-blue-400 rounded-t opacity-100 cursor-pointer "
	return (
		<li
			className={
				current
					? selectedStyles
					: "px-4 py-2 font-semibold rounded-t opacity-50 cursor-pointer border-b-4"
			}
		>
			<a id="tab" onClick={() => onClick(id)}>
				{title}
			</a>
		</li>
	)
}

const TabContent = ({ children }) => {
	return <div id="tab-contents">{children}</div>
}

const DateInformation = ({ productId, from, authorizedOn, createdDate }) => {
	if (createdDate) {
		return (
			<span>
				—{" "}
				{new Intl.DateTimeFormat("en-AU", {
					timeZone: "Australia/NSW",
					year: "numeric",
					month: "long",
					day: "2-digit",
				}).format(new Date(createdDate))}
			</span>
		)
	}

	return productId?.length > 0 ? (
		<span>
			—{" "}
			{new Intl.DateTimeFormat("en-AU", {
				timeZone: "Australia/NSW",
				year: "numeric",
				month: "long",
				day: "2-digit",
			}).format(new Date(from))}
		</span>
	) : (
		<span>
			—{" "}
			{new Intl.DateTimeFormat("en-AU", {
				timeZone: "Australia/NSW",
				year: "numeric",
				month: "long",
				day: "2-digit",
			}).format(new Date(authorizedOn))}
		</span>
	)
}

const EventItem = ({
	item: {
		permalink,
		name,
		excerpt,
		from,
		eventType,
		eventId,
		authorizedOn,
		file,
		fileName,
		createdDate,
		contentVisibility,
	},
}) => {
	const linkUrl = file
		? ""
		: eventId?.length > 0
		? `/events/${permalink}`
		: `/news/${permalink}`

	const type = file ? "Asset" : eventId?.length > 0 ? "Event" : "News"

	return (
		<div className="flex py-4 flex-col-reverse sm:flex-row">
			<div className="flex-grow">
				{!file ? (
					<h4 className="text-lg font-bold text-blue-500">
						<Link to={linkUrl}>{name}</Link>
					</h4>
				) : (
					<h4 className="text-lg font-bold text-blue-500">
						<Resource
							resource={{
								file: file,
								fileName: fileName,
								name: name,
								contentVisibility: contentVisibility,
								permalink: permalink,
							}}
						/>
					</h4>
				)}
				{!file && (
					<div
						dangerouslySetInnerHTML={{
							__html: excerpt?.slice(0, 250 || excerpt.length),
						}}
					></div>
				)}
				<p className="py-1 text-xs text-sm font-semibold">
					{type}{" "}
					<DateInformation
						productId={eventId}
						from={from}
						authorizedOn={authorizedOn}
						createdDate={createdDate}
					/>
				</p>
			</div>
		</div>
	)
}

export default InterestTopicItem

export const pageQuery = graphql`
	query InterestTopicItemQuery($itemId: String!) {
		interestGroup(interestGroupId: { eq: $itemId }) {
			name
			permalink
			interestGroupId
		}
		allEventItem(filter: { viewInList: { ne: false } }) {
			nodes {
				eventType
				eventId
				name
				permalink
				from
				excerpt
			}
		}
		allNewsItem {
			nodes {
				newsId
				permalink
				name
				excerpt
				authorizedOn
				interestGroups {
					interestGroupId
				}
			}
		}
		allFreeResources {
			nodes {
				id
				name
				file
				fileName
				createdDate
				contentVisibility
				permalink
			}
		}
		allResourceInterestGroups {
			nodes {
				fileId
				interestGroups
			}
		}
		allEventInterestGroups {
			nodes {
				eventId
				interestGroups
			}
		}
	}
`
